@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../../less/variables";

.homeColumnHeader {
  cursor: pointer;
  display: flex;
  align-items: baseline;
  margin-bottom: 12px;
  justify-content: space-between;
  height: 30px; // to avoid jumps when toggling to search input
  position: relative;
  h2{
    margin-right: 10px;
    font-weight: bold;
    font-size: 1.3rem;
  }
  .columnActions{
    display: flex;
    align-items: center;
    i{
      margin-left: 8px;
    }
  }
  i{
    color: @grayText;
  }
  .homeColumnSearch{
    //to fix angular shit
    width: 100%;
    z-index: 1; // to cover the favorites filter
  }
}