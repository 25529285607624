@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#rooms {
	position: relative;
	width: 250px;
	&.homeColumn{
		padding: 0;
	}
	#roomsList{
		padding-bottom: 20px;
		> div{
			margin-bottom: 12px;
		}
		> button{
			width: 100%;
		}
	}

	#noRooms{
		text-align: center;
		b{
			font-weight: 100;
		}
		a{
			text-decoration: underline;
			color: @color0_dark
		}
	}

	#roomListHeaderWrapper{
		.roomListHeaderWrapper_btn{
			color: @grayText;
			.normalText;
			position: absolute;

		}
		.createRoom{
			right: 22px;
			top: 0;
		}
		.filterFavorites{
			top: 0px;
			right: 44px;

		}
	}
}