.modal.song-modal .modal-content {
  width: 550px;
}
.modal.song-modal .modal-content .modal-header .modal-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal.song-modal .modal-body {
  padding: 0px !important;
}
.modal.song-modal .modal-body > div {
  margin-top: 20px;
}
#add-song-form {
  background-color: white;
  border-radius: 0 0 20px 20px;
}
#add-song-form h2 {
  font-size: 1.2rem;
  margin: 3px 7px 8px;
  padding-top: 4px;
}
#add-song-form .song-preview-player-holder .previewLoadingTxt {
  margin-bottom: 5px;
}
#add-song-form #addSong-rightPane {
  display: flex;
  align-items: center;
  width: 100%;
}
#add-song-form #addSong-rightPane #songModal_thumbnailHolder {
  position: relative;
  margin-right: 10px;
}
#add-song-form #addSong-rightPane #songModal_thumbnailHolder #duration-holder {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 5px;
  font-size: 0.9rem;
  color: white;
}
#add-song-form #addSong-rightPane .song-details {
  height: 100%;
  display: flex;
  flex-direction: column;
}
#add-song-form #addSong-rightPane .song-details #songActions {
  color: #2196F3;
  font-size: 1.2rem;
}
#add-song-form #addSong-rightPane .song-details #songActions > * {
  margin-right: 10px;
}
#add-song-form #addSong-rightPane .song-details h4 {
  font-size: 1.4rem;
  display: flex;
  align-items: baseline;
  margin: -7px 0px 0px;
  font-weight: normal;
}
#add-song-form #addSong-rightPane .song-details .song-link {
  float: left;
  cursor: text;
  max-width: 330px;
  line-height: 23px;
  color: #404040;
  margin-right: 15px;
}
#add-song-form #addSong-rightPane .estimatedPlaylistIndex {
  color: #6f6f6f;
  font-size: 0.9rem;
}
#add-song-form .song-row {
  display: flex;
  justify-content: space-between;
  padding: 0px 8px 8px;
  margin-bottom: 2px;
}
#add-song-form .song-row .addSongBtn {
  font-size: 70px;
  margin-right: 2px;
}
#add-song-form .song-details > h3 {
  margin: 0;
}
#add-song-form .error {
  font-size: 0.9rem;
  border-radius: 0px;
  text-align: center;
}
#add-song-form #firstTimer {
  background-color: #53bd87;
  color: white;
  text-align: center;
  padding: 3px 0px 5px;
}
#add-song-form #insights {
  padding: 6px 8px 10px;
  line-height: 25px;
}
#add-song-form #additionalData {
  background-color: #f8f8f8;
  color: black;
  border-radius: 0 0 20px 20px;
  padding: 12px 0 8px;
}
#add-song-form #additionalData h5 {
  margin: 0px;
  font-weight: bold;
  padding: 0px 8px;
  font-size: 1.2rem;
}
#add-song-form #additionalData h5 i {
  margin-right: 1px;
  color: #f32196;
}
#add-song-form #additionalData #wiki {
  background-color: #e6e6e6;
  min-height: 51px;
  padding: 9px 8px 1px;
  font-size: 0.9rem;
}
#add-song-form #additionalData #wiki #readMore {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  text-align: center;
  color: black;
  padding: 2px 0px 3px;
  font-weight: bold;
}
#add-song-form .song-thumb {
  width: 100px;
}
#add-song-form .estimatedPlaylistIndex {
  margin-top: 5px;
}
#add-song-form .clickToPreview {
  margin-top: 5px;
  margin-left: 1px;
  color: #f32196;
  display: flex;
  align-items: center;
}
#add-song-form .clickToPreview i {
  font-size: 0.8rem;
  margin-right: 4px;
}
#add-song-form .clickToPreview a {
  color: #f32196;
  font-size: 0.9rem;
  text-decoration: none;
}
#add-song-holder.full-screen-player {
  height: 540px;
  max-height: none;
}
#add-song-holder .content {
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  z-index: 1;
  position: relative;
}
#add-song-holder .content #moreFrom {
  font-size: 0.9rem;
  margin-bottom: 5px;
  display: flex;
}
#add-song-holder .content #moreFrom button {
  font-weight: normal;
  padding: 0px 7px 2px;
  margin-left: 4px;
  max-width: 120px;
  border-radius: 4px;
}
#add-song-holder .content .search-box > input {
  border: none;
  height: 40px;
  padding: 0 41px 3px;
  margin: 0px;
  border: 1px solid lightgrey;
}
#add-song-holder .content .search-box > input:focus {
  box-shadow: inset 0px 0px 5px 0px grey;
}
#add-song-holder .content .search-box > i.search-icon,
#add-song-holder .content .search-box i.search-error-icon {
  font-size: 1.6rem;
  color: #6f6f6f;
  left: 12px;
  position: absolute;
  top: 9px;
}
#add-song-holder .content .search-box > i.search-error-icon {
  color: red;
  left: 10px;
}
#add-song-holder .content .ai-search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-top: 10px;
  justify-content: flex-start;
  gap: 10px;
  position: relative;
}
#add-song-holder .content .ai-search-box input {
  width: 90%;
  height: 40px;
  font-size: 1.1em;
}
#add-song-holder .content .ai-search-box button {
  border-radius: 6px;
}
#add-song-holder .content .ai-search-box .bGPT {
  left: auto;
  right: 57px;
  top: 6px;
}
#add-song-holder .content .ai-search-box .results {
  position: absolute;
  top: 40px;
  left: 11px;
  background-color: white;
  padding: 10px;
  width: 88%;
}
#add-song-holder .content .ai-search-box .results .item {
  cursor: pointer;
}
#add-song-holder .content .ai-search-box .results .item:hover {
  background-color: #efefef;
}
#add-song-holder .content > .song-form-holder {
  position: relative;
}
#add-song-holder .content > .song-form-holder > i.back-to-search-results {
  position: absolute;
  top: 5px;
  right: 13px;
}
#add-song-holder .content > .song-form-holder .song-link {
  max-width: 274px !important;
}
#add-song-holder .btn-hide-search {
  position: absolute;
  top: 4px;
  right: 6px;
  z-index: 1;
  font-size: 1.2rem;
}
#add-song-holder .search-box {
  position: relative;
}
#add-song-holder #youtube-search-results-holder {
  position: relative;
  top: -5px;
  width: auto;
}
#add-song-holder .float-help {
  right: 3px;
  top: 2px;
}
#add-song-holder .youtube-search-result {
  cursor: pointer;
}
#add-song-holder .youtube-search-result #youChooseToPlayDetails {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 1.2rem;
  padding-bottom: 3px;
}
#add-song-holder .youtube-search-result .ytResultLayer {
  display: none;
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
#add-song-holder .youtube-search-result .ytResultLayer .errorMsg {
  font-weight: bold;
  color: #f32196;
}
#add-song-holder .youtube-search-result .ytResultLayer .ytResultLayer_playBtn .addSongBtn {
  font-size: 39px;
  margin-left: 25px;
}
#add-song-holder .youtube-search-result .ytResultLayer .layerOptions {
  z-index: 1;
  color: #f32196;
  padding-top: 1pt;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  align-items: end;
}
#add-song-holder .youtube-search-result .ytResultLayer .layerOptions ul {
  padding-left: 30px;
  line-height: 16px;
  margin-bottom: 0;
  font-size: 0.8rem;
}
#add-song-holder .youtube-search-result .ytResultLayer .layerOptions ul li {
  cursor: pointer;
}
#add-song-holder .youtube-search-result .ytResultLayer .layerOptions ul li i {
  font-size: 1.2rem;
}
#add-song-holder .youtube-search-result .ytResultLayer .layerOptions ul:hover {
  color: #a50960;
}
#add-song-holder .youtube-search-result:hover .ytResultLayer {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
#add-song-holder .digDeeper {
  text-align: center;
  padding: 7px 0px 8px;
  color: #2196F3;
  cursor: pointer;
}
#add-song-holder .digDeeper .fa-magic {
  margin-left: 1px;
  transform: rotateY(180deg);
}
#add-song-holder .digDeeper .paidDigDeeper {
  color: #eccc1b;
  margin-left: 5px;
}
#add-song-holder .digDeeper .paidDigDeeper i {
  margin-left: 1px;
}
#add-song-holder .input-holder .youtube-search-result {
  border-right: 1px solid #ccc;
  border-left: 1px solid #cccccc;
  border-bottom: none;
  background-color: #bcbcbc;
  display: flex;
}
#add-song-holder input#txtNewSong {
  font-size: 1.2rem;
  width: 100%;
  border: 1px solid 1px solid rgba(0, 0, 0, 0.1);
}
#add-song-holder input#txtNewSong[disabled] {
  cursor: default;
}
#add-song-holder input#txtNewSong.with-bottom-elem {
  border-radius: 4px 4px 0 0;
}
#add-song-holder .btn-reset-all,
#add-song-holder .back-to-suggestions-results {
  color: #BCBCBC;
  font-size: 1.4rem;
  position: absolute;
  right: 14px;
  top: 14px;
}
#add-song-holder .back-to-suggestions-results {
  right: 40px;
  top: 14px;
}
#add-song-holder input#txtOneLiner.with-youtube-results-open {
  border-radius: 0;
  height: 35px;
}
#add-song-holder > .btn-add-song-holder {
  text-align: center;
}
#add-song-holder > .btn-add-song-holder > .btn-add-song {
  cursor: pointer;
  font-size: 2.4em;
}
#add-song-holder > .btn-add-song-holder > .btn-add-song:hover {
  color: #898989;
}
#add-song-holder #youtube-search-results-holder .add-song {
  float: right;
}
#add-song-holder #youtube-search-results-holder .add-song img {
  position: relative;
  right: 20px;
  top: 6px;
  display: none;
}
#add-song-holder #youtube-search-results-holder,
#add-song-holder #youtube-suggestions-results,
#add-song-holder #youtube-search-no-results-message {
  background-color: #FFFFFF;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-radius: 0 0 5px 5px;
  position: absolute;
  overflow: hidden;
  max-height: 255px;
  z-index: 997;
  width: 100%;
  top: 70px;
  font-size: 1.2rem;
}
#add-song-holder #youtube-search-no-results-message {
  padding: 20px;
}
#add-song-holder #youtube-search-no-results-message .digDeeper {
  margin-top: 10px;
}
#add-song-holder .youtube-search-result,
#add-song-holder .youtube-suggestion-search-result {
  display: flex;
  position: relative;
  line-height: 20px;
  padding: 3px 2px 2px 3px;
  width: 100%;
}
#add-song-holder .youtube-search-result:nth-child(even) {
  background-color: #ececec;
}
#add-song-holder .youtube-suggestion-search-result {
  height: 30px;
  font-size: 1.2rem;
}
#add-song-holder .youtube-suggestion-search-result:nth-last-child(even) {
  background-color: #ececec;
}
#add-song-holder .youtube-search-result:hover,
#add-song-holder .youtube-search-result.hovered,
#add-song-holder .youtube-suggestion-search-result:hover,
#add-song-holder .youtube-suggestion-search-result.hovered {
  background-color: #FFF9E1;
}
#add-song-holder .youtube-search-result:hover .add-song img,
#add-song-holder .youtube-search-result.hovered .add-song img,
#add-song-holder .youtube-suggestion-search-result:hover .add-song img,
#add-song-holder .youtube-suggestion-search-result.hovered .add-song img {
  display: block !important;
}
#add-song-holder .youtube-search-result:last-child,
#add-song-holder .youtube-suggestion-search-result:last-child {
  border-bottom: none;
}
#add-song-holder .youtube-search-result #youChooseToPlayDetails .song-title {
  display: flex;
  align-items: center;
}
#add-song-holder .youtube-search-result #youChooseToPlayDetails .song-title .add-song-to-beat-box-holder {
  bottom: 6px;
  position: relative;
}
@-moz-document url-prefix() {
  #add-song-holder .youtube-search-result #youChooseToPlayDetails .song-title .add-song-to-beat-box-holder {
    bottom: 7px;
  }
}
#add-song-holder .youtube-search-result #youChooseToPlayDetails .song-title a {
  color: #3D3C49;
  display: inline-block;
  width: 350px;
  line-height: 24px;
}
#add-song-holder .youtube-search-result #youChooseToPlayDetails .song-title ::i-block-chrome,
#add-song-holder .youtube-search-result #youChooseToPlayDetails .song-title a {
  font-weight: normal;
}
#add-song-holder .youtube-search-result #youChooseToPlayDetails .song-title .clickToPreview {
  font-weight: normal;
  position: relative;
  margin-top: 0px;
}
#add-song-holder .youtube-search-result #youChooseToPlayDetails .song-title .clickToPreview a {
  color: #79A2A1;
}
#add-song-holder .youtube-search-result #youChooseToPlayDetails .song-title .clickToPreview i {
  top: 7px;
}
#add-song-holder .youtube-search-result #youChooseToPlayDetails .song-title .duration {
  color: #6f6f6f;
  margin-left: 10px;
}
#add-song-holder .youtube-search-result img.song-thumb {
  width: 65px;
  float: left;
  margin-right: 8px;
}
#add-song-holder .youtube-search-result .buttons-holder {
  position: relative;
  right: 0;
  top: 0;
  padding: 0 5px;
}
#add-song-holder .youtube-search-result .buttons-holder > i {
  display: block;
  margin-bottom: 3px;
}
#add-song-holder #similar-songs-holder {
  position: absolute;
  width: 100%;
  margin: 0 auto;
  height: 130px;
  bottom: 20px;
}
#add-song-holder #similar-songs-holder:after {
  clear: both;
  content: "";
  display: block;
}
#add-song-holder #similar-songs-holder #getInspiredControl {
  width: 100%;
  cursor: pointer;
  text-align: center;
  margin-bottom: 20px;
  height: 10px;
  margin-top: 21px;
  color: gray;
}
#add-song-holder #similar-songs-holder #getInspiredControl #getInspiredDot {
  color: #2196F3;
}
