@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

.roomsModal {
  .modal-content{
    max-width: @homepageWidth !important;
  }
}

#roomsPopup {
  .modal-body {
    #searchHolder{
      display: flex;
      align-items: center;
      .homeColumnSearch{
        flex-grow: 2;
      }
      .filterFavorites{
        .bigText;
        margin-left: 10px;
      }
    }
    .listHolder {
      max-height: 80vh;
      display: flex;
      flex-wrap: wrap;
      margin-top: 25px;
      .roomTile {
        margin-right: 25px;
        margin-bottom: 10px;
        .roomTileHolder {
          margin-bottom: 15px;
        }
      }
    }

    #noRooms{
      text-align: center;
      margin-top: 15px;
      b{
        font-weight: 100;
      }
      a{
        text-decoration: underline;
        color: @color0_dark
      }
    }
  }
}
