#sortOptionsHolder .sortOptions {
  position: absolute;
  top: 27px;
  left: 106px;
  background-color: #fff;
  z-index: 1;
  border: 1px solid #D2D2D2;
}
#sortOptionsHolder .sortOptions .sortOption {
  padding: 8px;
  cursor: pointer;
  white-space: nowrap;
}
#sortOptionsHolder .sortOptions .sortOption.selected {
  font-weight: 100;
}
#sortOptionsHolder .sortOptions .sortOption:hover {
  background-color: #dfdfdf;
}
