@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#sortOptionsHolder{
  .sortOptions {
    position: absolute;
    top: 27px;
    left: 106px;
    background-color: #fff;
    z-index: 1;
    border: @border;

    .sortOption {
      padding: 8px;
      .clickable;
      white-space: nowrap;

      &.selected {
        font-weight: 100;
      }

      &:hover {
        background-color: @bgColor_hover;
      }
    }
  }
}