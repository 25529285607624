#homePage {
  display: flex;
  flex-direction: row;
  margin-top: 67px;
  width: 1150px;
  border-radius: 20px;
}
#homePage .homeColumn {
  padding-right: 50px;
}
#homePage .middleColumn {
  width: 45vw;
  max-width: 620px;
  padding-top: 41px;
  position: relative;
}
#homePage .middleColumn .backButton {
  color: #6f6f6f;
  position: absolute;
  top: 5px;
  font-size: 1.3rem;
}
#homePage .middleColumn #userProfile {
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  background-color: white;
  padding: 10px;
}
