#user {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 12px;
  position: relative;
}
#user > a {
  display: flex;
  justify-content: stretch;
}
#user > a:hover {
  text-decoration: none;
}
#user:hover {
  text-decoration: none;
}
#user img {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}
#user .online {
  position: absolute;
  top: 0;
  left: 0;
  color: #39e737;
}
#user .details {
  margin: 0 5px 0 12px;
  width: 100px;
}
#user .details .userName {
  font-size: 1.1rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#user .details .musicTags {
  color: #f32196;
  font-size: 0.8rem;
}
#user .details .followers {
  font-size: 0.8rem;
  margin-top: 1px;
  color: #404040;
}
#user button {
  border-radius: 50px;
  background: transparent;
  color: #f651ad;
  transition-property: background-color;
  transition-duration: 0.3s;
  height: 30px;
  padding: 6px 12px 25px;
  font-size: 1.6rem;
}
#user button:hover {
  color: #d50c7c;
}
#user button.follows {
  color: #f651ad;
}
