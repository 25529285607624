@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../less/variables";
@import (reference) "../../../less/general";

#homePage {
  display: flex;
  flex-direction: row;
  margin-top: 67px;
  width: @homepageWidth;
  border-radius: @borderRadius;

  .homeColumn{
    padding-right: 50px;
  }

  .middleColumn {
    width: 45vw;
    max-width: 620px;
    padding-top: 41px;
    position: relative;

    .backButton {
      color: @grayText;
      position: absolute;
      top: 5px;
      font-size: 1.3rem;
    }
    #userProfile{
      .box;
    }
  }
}