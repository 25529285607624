@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#postDeletionPopup {
  .txtReason {
    width: 300px;
    height: 100px;
    margin: 10px 0;
  }
  b{
    font-weight: 100;
  }
}