.homeColumnHeader {
  cursor: pointer;
  display: flex;
  align-items: baseline;
  margin-bottom: 12px;
  justify-content: space-between;
  height: 30px;
  position: relative;
}
.homeColumnHeader h2 {
  margin-right: 10px;
  font-weight: bold;
  font-size: 1.3rem;
}
.homeColumnHeader .columnActions {
  display: flex;
  align-items: center;
}
.homeColumnHeader .columnActions i {
  margin-left: 8px;
}
.homeColumnHeader i {
  color: #6f6f6f;
}
.homeColumnHeader .homeColumnSearch {
  width: 100%;
  z-index: 1;
}
