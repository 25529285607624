#rooms {
  position: relative;
  width: 250px;
}
#rooms.homeColumn {
  padding: 0;
}
#rooms #roomsList {
  padding-bottom: 20px;
}
#rooms #roomsList > div {
  margin-bottom: 12px;
}
#rooms #roomsList > button {
  width: 100%;
}
#rooms #noRooms {
  text-align: center;
}
#rooms #noRooms b {
  font-weight: 100;
}
#rooms #noRooms a {
  text-decoration: underline;
  color: #0c7cd5;
}
#rooms #roomListHeaderWrapper .roomListHeaderWrapper_btn {
  color: #6f6f6f;
  font-size: 1.1rem;
  position: absolute;
}
#rooms #roomListHeaderWrapper .createRoom {
  right: 22px;
  top: 0;
}
#rooms #roomListHeaderWrapper .filterFavorites {
  top: 0px;
  right: 44px;
}
