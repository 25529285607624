@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../../less/variables";

#user {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 12px;
  position: relative;
  > a{
	  display: flex;
    justify-content: stretch;
      &:hover{
        text-decoration: none;
      }
  }
  &:hover{
    text-decoration: none;
  }
  img {
    border-radius: 50%;
    width: 48px; height: 48px;
  }
  .online {
    position: absolute;
    top: 0;
    left: 0;
    color: #39e737;
  }
  .details{
    margin: 0 5px 0 12px;
    width: 100px;
    .userName{
      .normalText;
      font-weight: bold;
      .ellipsis;
    }
    .musicTags{
      color: @color2;
      .smallerText;
    }
    .followers{
      .smallerText;
      margin-top: 1px;
      color: @textColor;
    }
  }
  button{
    border-radius: 50px;
    background: transparent;
    color: @color2_light;
    transition-property: background-color;
    transition-duration: .3s;
    height: 30px;
    padding: 6px 12px 25px;
    .biggerText;
    &:hover{
      color: @color2_dark;
    }
    &.follows {
      color: @color2_light;
    }
  }
}