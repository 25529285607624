@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "variables";
@import (reference) "updateInvalidEmail";
@import (reference) "songSearch";
@import 'animations';

#home{
    display: flex;
    justify-content: center;
    align-items: baseline;
}
#learn-more{
    .overlay{
        background-color: black;
        height: 100%;
        left: 0;
        opacity: 0.6;
        position: absolute;
        top: 0;
        width: 100%;
    }

    #bannerStats {
        //.gradient-generator(@gradColor3, @gradColor4);
        background-color:  @color2;
        padding: 10px 0 17px;
        font-size: 19px;
        .flex;
        color: white;
        b{
            font-weight: 100;
        }
        > div{
            margin: 0 5px;
        }
        > span{
           color: darken(@color2,25%);
        }
    }
    #features{
        display: flex;
        justify-content: space-around;
        background-color: @bgColor_lighter;
        > * {
            width: 100%;
            text-align: center;
        }
    }
}

.down-arrow-holder {
    display: table;
    margin: 0 auto;
    color: #000000;
    i {
        line-height: 15px;
        .tinyText;
        color: rgba(255, 255, 255, 0.7);
    }
}


.limboCTA{
    border-radius: 100px;
    word-spacing: -3px;
    padding: 12px 0px 16px;
    background-color: @color0;
    transition-duration: .5s;
    width: 203px;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    font-weight: bold;
    font-size: 20px;

    &.disabled {
        .notclickable;
    }
}

