@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#users {
	width: 281px;

	#usersList {
		> button{
			width: 100%;
			margin-top: 6px;
		}
	}
}