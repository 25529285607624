@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "variables";

.modal.song-modal {
    .modal-content {
        width: 550px;
        .modal-header {
            .modal-title{
                .ellipsis;
            }
        }
    }
    .modal-body {
        padding: 0px !important;
    }

    .modal-body > div {
        margin-top: 20px;
    }
}


#add-song-form{
    background-color: white;
    border-radius: 0 0 20px 20px;
    h2{
        .mediumText;
        margin: 3px 7px 8px;
        padding-top: 4px;
    }

    .song-preview-player-holder {
        .previewLoadingTxt{
            margin-bottom: 5px;
        }
    }

    #addSong-rightPane{
        display: flex;
        align-items: center;
        width: 100%;
        #songModal_thumbnailHolder {
            position: relative;
            margin-right: 10px;
            #duration-holder{
               position: absolute;
               top: 0px;
               right: 0px;
                background-color: @black_transparent;
                padding: 0 5px;
                .smallText;
                color: white;
            }
        }
        .song-details {
            height: 100%;
            display: flex;
            flex-direction: column;
            #songActions{
                color: @color0;
                .mediumText;
                > * {
                    margin-right: 10px;
                }
            }
            h4{
                .bigText;
                display: flex;
                align-items: baseline;
                margin: -7px 0px 0px;
                font-weight: normal;
            }
            .song-link {
                float: left;
                cursor: text;
                max-width: 330px;
                line-height: 23px;
                color: @textColor;
                margin-right: 15px;
            }
        }

        .estimatedPlaylistIndex {
            color: @grayText;
            .smallText;
        }
    }

    .song-row {
        display: flex;
        justify-content: space-between;
        padding: 0px 8px 8px;
        margin-bottom: 2px;
        .addSongBtn{
            font-size: 70px;
            margin-right: 2px;
        }
    }

    .song-details > h3 {
        margin: 0;
    }

    .error {
        .smallText;
        border-radius: 0px;
        text-align: center;
    }

    #firstTimer{
        background-color: @green4_dark;
        color: white;
        text-align: center;
        padding: 3px 0px 5px;
    }

    #insights{
        padding: 6px 8px 10px;
        line-height: 25px;
    }
    #additionalData{
        background-color: @bgColor_lighter;
        color: black;
        border-radius: 0 0 20px 20px;
        padding: 12px 0 8px;
        h5{
            margin: 0px;
            font-weight: bold;
            padding: 0px 8px;
            .mediumText;
            i{
                margin-right: 1px;
                color: @color2;
            }
        }

        #wiki{
            background-color: @bgColor;
            min-height: 51px;
            padding:9px 8px 1px;
            .smallText;
            #readMore{
                background-color: rgba(255, 255, 255, 0.8);
                position: absolute;
                bottom: 0px;
                width: 100%;
                left: 0px;
                text-align: center;
                color: black;
                padding: 2px 0px 3px;
                font-weight: bold;
            }
        }
    }
    .song-thumb{
        width: 100px;
    }

    .estimatedPlaylistIndex{
        margin-top: 5px;
    }
    .clickToPreview {
        margin-top: 5px;
        margin-left: 1px;
        color: @color2;
        display: flex;
        align-items: center;
        i {
            .smallerText;
            margin-right: 4px;
        }
        a {
            color: @color2;
            .smallText;
            text-decoration: none;
        }
    }
}

#add-song-holder {

    &.full-screen-player {
        height: 540px;
        max-height: none;
    }

    .content {
        width: 100%;
        border-radius: 4px 4px 0px 0px;
        z-index: 1; //to be over the get inspired dot
        position: relative;
        #moreFrom{
            .smallText;
            margin-bottom: 5px;
            display: flex;
            button{
                font-weight: normal;
                padding: 0px 7px 2px;
                margin-left: 4px;
                max-width: 120px;
                border-radius: 4px;
            }
        }
        .search-box{
            > input {
                border: none;
                height: 40px;
                padding: 0 41px 3px;
                margin: 0px;
                border: 1px solid lightgrey;
                &:focus{
                    box-shadow: inset 0px 0px 5px 0px grey;
                }
            }
            > i.search-icon, i.search-error-icon {
                .biggerText;
                color: @grayText;
                left: 12px;
                position: absolute;
                top: 9px;
            }

            > i.search-error-icon{
                color: red;
                left: 10px;
            }
        }

        .ai-search-box {
            .flex;
            margin-top: 10px;
            justify-content: flex-start;
            gap: 10px;
            position: relative;

            input {
                width: 90%;
                height: 40px;
                font-size: 1.1em;
            }

            button {
                border-radius: 6px;
            }

            .bGPT {
                left: auto;
                right: 57px;
                top: 6px;
            }

            .results {
                position: absolute;
                top: 40px;
                left: 11px;
                background-color: white;
                padding: 10px;
                width: 88%;

                .item {
                    .clickable;

                    &:hover {
                        background-color: #efefef;
                    }
                }
            }
        }

        > .song-form-holder {
            position: relative;
            //padding: 0 30px 15px;

            > i.back-to-search-results {
                position: absolute;
                top: 5px;
                right: 13px;
            }
            .song-link{
                max-width: 274px !important;
            }
        }
    }

    .btn-hide-search {
        position: absolute;
        top: 4px;
        right: 6px;
        z-index: 1;
        .mediumText;
    }

    .search-box {
        position: relative;
    }

    #youtube-search-results-holder {
        position: relative;
        top: -5px;
        width: auto;
    }

    .float-help {
        right: 3px;
        top: 2px;
    }
    .youtube-search-result {
        cursor: pointer;
        #youChooseToPlayDetails{
            .flex;
            .mediumText;
            padding-bottom: 3px;
        }
        .ytResultLayer{
            display: none;
            background-color: rgba(255,255,255,0.9);
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            width: 100%;
            text-align: center;
            font-weight: 500;
            .errorMsg{
                font-weight: bold;
                color: @color2;
            }
            .ytResultLayer_playBtn{
                .addSongBtn {
                    font-size: 39px;
                    margin-left: 25px;
                }
            }
            .layerOptions{
                z-index: 1;
                color: @color2;
                padding-top: 1pt;
                margin-right: 10px;
                .flex;
                align-items: end;
                ul{
                    padding-left: 30px;
                    line-height: 16px;
                    margin-bottom: 0;
                    .smallerText;
                    li{
                        cursor: pointer;
                        i{
                            .mediumText;
                        }
                    }
                    &:hover{
                        color: darken(@color2, 20%);
                    }
                }
            }
        }
        &:hover{
            .ytResultLayer{
                .flex;
            }
        }
    }

    .digDeeper{
        text-align: center;
        padding: 7px 0px 8px;
        color: @color0;
        cursor: pointer;
        .fa-magic{
            margin-left: 1px;
            transform: rotateY(180deg);
        }
        .paidDigDeeper{
            color: @beats;
            margin-left: 5px;
            i{
                margin-left: 1px;
            }
        }
    }
    .input-holder .youtube-search-result {
        border-right: 1px solid #ccc;
        border-left: 1px solid #cccccc;
        border-bottom: none;
        background-color: #bcbcbc;
        display: flex;
    }

    input#txtNewSong {
        .mediumText;
        width: 100%;
        border: 1px solid 1px solid rgba(0,0,0,0.1);
    }

    input#txtNewSong[disabled] {
        cursor: default;
    }

    input#txtNewSong.with-bottom-elem {
        border-radius: 4px 4px 0 0;
    }

    .btn-reset-all, .back-to-suggestions-results {
        color: #BCBCBC;
        .bigText;
        position: absolute;
        right: 14px;
        top: 14px;
    }

    .back-to-suggestions-results{
        right: 40px;
        top: 14px;
    }

    input#txtOneLiner.with-youtube-results-open {
        border-radius: 0;
        height: 35px;
    }

    > .btn-add-song-holder {
        text-align: center;
    }

    > .btn-add-song-holder {
        > .btn-add-song {
            cursor: pointer;
            font-size: 2.4em;
        }
    }

    > .btn-add-song-holder {
        > .btn-add-song:hover {
            color: #898989;
        }
    }

    #youtube-search-results-holder .add-song {
        float: right;
        img {
            position: relative;
            right: 20px;
            top: 6px;
            display: none;
        }
    }

    #youtube-search-results-holder,
    #youtube-suggestions-results,
    #youtube-search-no-results-message {
        background-color: #FFFFFF;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        border-radius: 0 0 5px 5px;
        position: absolute;
        overflow: hidden;
        max-height: 255px;
        z-index: 997;
        width: 100%;
        top: 70px;
        .mediumText;
    }

    #youtube-search-no-results-message {
        padding: 20px;
        .digDeeper{
            margin-top: 10px;
        }
    }

    .youtube-search-result, .youtube-suggestion-search-result {
        display: flex;
        position: relative;
        line-height: 20px;
        padding: 3px 2px 2px 3px;
        width: 100%;
    }
    .youtube-search-result:nth-child(even) {
        background-color: @stripesBg;
    }

    .youtube-suggestion-search-result {
        height: 30px;
        .mediumText;
        &:nth-last-child(even) {
            background-color: @stripesBg;
        }
    }

    .youtube-search-result:hover,
    .youtube-search-result.hovered,
    .youtube-suggestion-search-result:hover,
    .youtube-suggestion-search-result.hovered {
        background-color: #FFF9E1;
        .add-song img {
            display: block !important;
        }
    }

    .youtube-search-result:last-child,
    .youtube-suggestion-search-result:last-child {
        border-bottom: none;
    }

    .youtube-search-result #youChooseToPlayDetails .song-title {
        display: flex;
        align-items: center;
        .add-song-to-beat-box-holder {
            bottom: 6px;
            position: relative;
        }
        @-moz-document url-prefix() {
            .add-song-to-beat-box-holder {
                bottom: 7px;
            }
        }
        a {
            color: @color1;
            display: inline-block;
            width: 350px;
            line-height: 24px;
        }
        ::i-block-chrome, a {
            font-weight: normal;
        }
        .clickToPreview {
            font-weight: normal;
            position: relative;
            margin-top: 0px;
            a {
                color: @color4;
            }
            i {
                top: 7px;
            }
        }
        .duration {
            color: @grayText;
            margin-left: 10px;
        }
    }

    .youtube-search-result img.song-thumb {
        width: 65px;
        float: left;
        margin-right: 8px;
    }

    .youtube-search-result .buttons-holder {

        position: relative;
        right: 0;
        top: 0;
        padding: 0 5px;
    }

    .youtube-search-result .buttons-holder > i {
        display: block;
        margin-bottom: 3px;
    }
    #similar-songs-holder {
        position: absolute;
        width: 100%;
        margin: 0 auto;
        height: 130px;
        bottom: 20px;

        &:after {
            clear: both;
            content: "";
            display: block;
        }

        #getInspiredControl{
            width: 100%;
            cursor: pointer;
            text-align: center;
            margin-bottom: 20px;
            height: 10px;
            margin-top: 21px;
            color: gray;

            #getInspiredDot {
                color: @color0;
            }
        }
    }
}
